//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    padding: get($content-spacing, desktop) 0;

    // Toolbar enabled and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-fixed) & {
      padding-top: 0;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .content {
    padding: get($content-spacing, tablet-and-mobile) 0;

    // Toolbar enabled mode and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
      padding-top: 0;
    }
  }
}

/* Fix dropdown pagination kendo grid */
.k-pager-sizes .k-dropdownlist .k-input-inner .k-input-value-text {
  overflow: visible !important;
}

.k-pager-sizes .k-dropdownlist .k-input-inner {
  padding:0 5px 0 10px !important;
}

.k-pager-sizes .k-dropdown-list, .k-pager-sizes .k-dropdown, .k-pager-sizes .k-dropdownlist, .k-pager-sizes > select {
  width: auto !important;
}
