//
// Custom
//

/* VIAGGI CARICO - Inizio */

.accordion-header-viaggi {
  overflow: hidden;
  -webkit-border-radius: 6px;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius: 6px;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  border-radius: 6px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-header-viaggi > button {
  /* background-color: #FFFF67; */
  font-size: 20px;
  font-weight: 700;
  padding: 6px;
}

.table-viaggi-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 224px !important;
  background-color: #e9e9e9;
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-viaggi-fixed tbody tr {
  border-width: 0 0 1px 0 !important;
  border-color: #d3d3d3 !important;
  border-style: solid !important;
}

.table-viaggi-fixed thead th,
.table-viaggi-fixed tbody tr {
  font-size: 9.5pt !important;
}

.table-viaggi-modifica-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 190px !important;
  background-color: #e9e9e9;
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-viaggi-modifica-fixed tbody tr {
  border-width: 0 0 1px 0 !important;
  border-color: #d3d3d3 !important;
  border-style: solid !important;
}

.table-viaggi-modifica-fixed thead th,
.table-viaggi-modifica-fixed tbody tr {
  font-size: 9.5pt !important;
}

tr.tr-viaggi-footer {
  background-color: #ffff67 !important;
  font-size: 9.5pt !important;
  font-weight: 600 !important;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
  padding: 0px;
}

.table-viaggi-precarico-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 30px !important;
  background-color: #e9e9e9;
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-viaggi-precarico-fixed thead th,
.table-viaggi-precarico-fixed tbody tr {
  font-size: 9.5pt !important;
}

/* VIAGGI CARICO - Fine */

/* MAGAZZINO - Inizio */

.table-magazzino-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
  background-color: #ffffff;
  font-weight: 600;
}

.table-magazzino-fixed tbody tr {
  border-width: 0 0 1px 0 !important;
  border-color: #d3d3d3 !important;
  border-style: solid !important;
}

.td-header-magazzino-giallo {
  background-color: #ffff66 !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.table-magazzino-componenti tr th,
.table-magazzino-componenti tr td {
  font-size: 8pt !important;
  padding: 3px 0 !important;
}

.table-magazzino-vista-clienti-aperti-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 120px;
  background-color: #ffffff;
  font-weight: 600;
}

.table-magazzino-vista-clienti-aperti-fixed tbody tr {
  border-width: 0 0 1px 0 !important;
  border-color: #d3d3d3 !important;
  border-style: solid !important;
}

.td-header-magazzino-vista-clienti-aperti-verde {
  background-color: #5db85b !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  font-size: 12pt !important;
}

.table-magazzino-precarico-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 120px !important;
  background-color: #e9e9e9;
  padding-top: 2px;
  padding-bottom: 2px;
}

.table-magazzino-precarico-fixed tbody tr {
  border-width: 0 0 1px 0 !important;
  border-color: #d3d3d3 !important;
  border-style: solid !important;
}

.table-magazzino-precarico-fixed thead th,
.table-magazzino-precarico-fixed tbody tr {
  font-size: 9.5pt !important;
}

/* MAGAZZINO - Fine */

/* FORNITORI - Inizio */

.table-fornitori-ordini-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 276px !important;
  background-color: #e9e9e9;
  padding-top: 2px;
  padding-bottom: 2px;
  z-index: 500;
}

.table-fornitori-ordini-fixed tbody tr {
  border-width: 0 0 1px 0 !important;
  border-color: #d3d3d3 !important;
  border-style: solid !important;
}

.table-fornitori-ordini-fixed thead th,
.table-fornitori-ordini-fixed tbody tr {
  font-size: 9.5pt !important;
}

/* FORNITORI - Fine */

/* AEFFE - Inizio */

.table-aeffe-fixed thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 120px !important;
  background-color: #e9e9e9;
  padding-top: 2px;
  padding-bottom: 2px;
  z-index: 500;
}

.table-aeffe-fixed tbody tr {
  border-width: 0 0 1px 0 !important;
  border-color: #d3d3d3 !important;
  border-style: solid !important;
}

.table-aeffe-fixed thead th,
.table-aeffe-fixed tbody tr {
  font-size: 9.5pt !important;
}

/* FORNITORI - Fine */

.k-notification-group {
  z-index: 99999 !important;
}
